<template>
    <!-- NOTE: q-page sets min-height, but not height, which results in descendents
      not having a set height or min-height, unless explicitly set.
      using % on descendents height will fail unless we wrap everything in a grid layout: -->
    <q-page
        class="page"
        :class="[
            isStacked ? 're-stacked' : 're-side-by-side',
            $slots.sidebar || stepper ? 'with-sidebar' : null,
            props.isCentered && !$q.screen.xs ? 're-centered' : null,
        ]"
    >
        <!-- optional sidebar it if exists -->
        <div v-if="$slots.sidebar || stepper" class="sidebar" :class="props.stickyTop ? 'sticky-top' : ''">
            <slot v-if="$slots.sidebar" name="sidebar"></slot>
            <stepper
                v-else-if="stepper"
                :stepper="props.stepper"
                v-bind="stepperProps"
                :is-horizontal="isStacked"
            >
                <template v-for="slot in Object.keys($slots).filter((s) => s.startsWith('stepper-'))" #[slot]>
                    <slot :name="slot"></slot>
                </template>
            </stepper>
        </div>
        <div
            :class="{
                [`width-${props.maxWidth}`]: props.maxWidth !== 'full',
            }"
        >
            <template v-if="$slots.banner">
                <slot name="banner"></slot>
            </template>
            <div
                :class="{
                    [props.mainContentClass]: !!props.mainContentClass,
                    fit: !$slots.banner,
                }"
            >
                <re-title
                    v-if="props.pageTitle || props.tooltip"
                    :tooltip="props.tooltip"
                    :class="props.centerTitle ? 'row justify-center' : ''"
                >
                    {{ props.pageTitle }}
                </re-title>
                <slot></slot>
            </div>
        </div>
        <q-page-sticky
            v-if="props.showBackground"
            position="bottom-left"
            :style="{ ...defaultBackgroundStyle, ...backgroundStyle }"
        />
    </q-page>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import Stepper from '@/components/elements/Stepper.vue'

const props = defineProps({
    // Move to template props:
    maxWidth: {
        type: String,
        default: 'md',
        validator: (v) => ['sm', 'md', 'lg', 'xl', 'full'].includes(v),
    },
    tooltip: {
        type: String,
        default: undefined,
    },
    backgroundStyle: {
        type: Object,
        default: undefined,
    },
    showBackground: {
        type: Boolean,
        default: false,
    },
    pageTitle: {
        type: String,
        default: '',
    },
    centerTitle: {
        type: Boolean,
        default: false,
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    isCentered: {
        type: Boolean,
        default: false,
    },
    mainContentClass: {
        type: String,
        default: 're-main-content',
    },
    stepper: {
        type: Object,
        default: undefined,
    },
    stepperProps: {
        type: Object,
        default: undefined,
    },
    stacked: {
        type: Boolean,
        default: undefined,
    },
    stickyTop: {
        type: Boolean,
        default: true,
    },
})

const q = useQuasar()

const isStacked = computed(() => props.stacked ?? q.screen.lt.md)

const backgroundUrl = new URL('@/assets/icons/img-skyline.svg', import.meta.url).pathname

const defaultBackgroundStyle = ref({
    background: `url('${backgroundUrl}') no-repeat`,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    height: '100%',
    width: '100%',
    zIndex: -100,
})
</script>
<style scoped lang="scss">
.page {
    .sidebar {
        background-color: white;
        z-index: 1001;
    }

    &.re-stacked {
        display: grid;

        &.with-sidebar {
            grid-template-rows: min-content auto;
        }

        .sticky-top {
            top: 50px;
        }

        &.re-centered {
            justify-content: center;
        }
    }
    &.re-side-by-side {
        display: grid;
        &.with-sidebar {
            grid-template-columns: min-content auto;
        }

        .sidebar {
            min-width: 250px; // FIXME: define reactive min stepper width
            height: calc(100vh - 50px); // Needed for sticky to work
        }

        &.re-centered {
            justify-content: center;
        }
    }
    .width-sm {
        max-width: 600px;
    }
    .width-md {
        max-width: 1024px;
    }
    .width-lg {
        max-width: 1440px;
    }
    .width-xl {
        max-width: 1920px;
    }

    .sticky-top {
        position: sticky;
        top: 50px;
        overflow-y: auto;
    }
}
</style>
