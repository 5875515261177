<template>
    <span :class="props.row.last_dvm_action_at && props.value === 'draft' ? 'text-bold text-negative' : ''">
        {{
            props.row.valuation_type === 'ers' && props.value === 'valued'
                ? $t('status.completed')
                : $t(`status.${props.value}`)
        }}
    </span>
    <re-tooltip
        v-if="props.row.last_dvm_action_at && props.row.status === 'draft'"
        color="negative"
        :label="$t('monolith.dashboard.review_tooltip')"
        multilined
        position="is-right"
    >
        <q-avatar size="xs" color="negative" text-color="white">
            <span class="text-weight-bold">!</span>
        </q-avatar>
    </re-tooltip>
    <div class="row q-gutter-xs q-mt-xs">
        <q-badge
            v-if="isResubmitted && !isOnlyOvmValuer && isValuerOrTypeDispatcher"
            color="warning-light"
            multi-line
        >
            {{ $t('monolith.dashboard.badges.resubmitted') }}
        </q-badge>
        <q-badge
            v-if="needsBorrowerEmail && (curUser.hasRole('dispatcher', 'ovm') || props.row.owner.is_self)"
            color="warning"
            multi-line
        >
            {{ $t('monolith.dashboard.badges.needs_borrower_email') }}
        </q-badge>
        <q-badge v-if="needsAppointmentConfirmation && isValuerOrTypeDispatcher" color="warning" multi-line>
            {{ $t('monolith.dashboard.badges.needs_appointment_confirmation') }}
        </q-badge>
        <q-badge v-if="needsBorrowerAction && curUser.hasRole('dispatcher', 'ovm')" color="warning">
            {{ $t('monolith.dashboard.badges.needs_borrower_action') }}
        </q-badge>
        <q-badge
            v-if="
                isAppointmentConfirmed &&
                (curUser.hasRole('dispatcher', 'ovm') || props.row.owner.is_self || props.row.valuer?.is_self)
            "
            color="positive"
        >
            {{ $t('monolith.dashboard.badges.appointment_confirmed') }}
        </q-badge>
    </div>
</template>
<script setup>
import { inject, computed } from 'vue'

// don't automatically insert attrs in root div:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    value: {
        type: String,
        required: true,
    },
})

const curUser = inject('curUser')

// TODO: consider abstracting these into a parent CellComponent.vue (if much overlap between cells)
const needsBorrowerEmail = computed(
    () => props.row.status === 'submitted' && props.row.ovm_status === 'borrower-registration-pending'
)
const needsAppointmentConfirmation = computed(
    () => props.row.status === 'submitted' && props.row.ovm_status === 'confirmation-pending'
)
const needsBorrowerAction = computed(
    () =>
        props.row.status === 'submitted' &&
        ['appointment-pending', 'payment-pending', 'borrower-details'].includes(props.row.ovm_status)
)
const isAppointmentConfirmed = computed(
    () => props.row.status === 'submitted' && props.row.ovm_status === 'valuation-pending'
)
const isResubmitted = computed(
    () =>
        props.row.status === 'submitted' &&
        props.row.last_dvm_action_at &&
        new Date(props.row.last_submission_at).getTime() > props.row.last_dvm_action_at
)
const isValuerOrTypeDispatcher = computed(
    () => curUser.hasRole('dispatcher', props.row.valuation_type) || props.row.valuer?.is_self
)
</script>
<style lang="scss" scoped>
.q-badge {
    word-wrap: normal;
    white-space: normal;
    word-break: normal;
}
</style>
