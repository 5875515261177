<template>
    <q-form ref="formRef" v-bind="$attrs" @input="isDirty = true">
        <slot></slot>
    </q-form>
</template>

<script setup>
import { ref, onMounted, computed, toRaw } from 'vue'

const formRef = ref(null)
const formComponents = ref([])
const hasErrors = computed(() => formComponents.value?.some((v) => v.hasError))
const isDirty = ref(false)
defineExpose({
    hasErrors,
    isDirty,
    formRef,
})

onMounted(() => {
    formComponents.value = formRef?.value.getValidationComponents().map((proxy) => toRaw(proxy))
})
</script>
