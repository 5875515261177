<template>
    <div class="app_card">
        <router-link :to="appRoute" :class="[$q.screen.xs && 'row']">
            <div class="col-6 app_card__pict column justify-center">
                <q-img :src="appImg" />
            </div>
            <div class="col-6 app_card__text">
                <div class="title">{{ $t(`monolith.apps.${appId}.name`) }}</div>
                <p v-if="$te(`monolith.apps.${appId}.description`)">
                    {{ $t(`monolith.apps.${appId}.description`) }}
                </p>
            </div>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'AppCard',
    inject: ['curUser'],
    props: {
        appId: {
            type: String,
            required: true,
        },
        appUrl: {
            type: String,
            default: null,
        },
    },
    computed: {
        appImg() {
            return new URL(`../../assets/img/apps/${this.appId}.jpg`, import.meta.url).href
        },
        appRoute() {
            if (this.appId === 'ers') {
                return {
                    name: 'ers.welcome',
                    query: { ...this.$route.query },
                }
            } else if (this.appId === 'oepc') {
                return {
                    name: 'oepc.request',
                    query: { ...this.$route.query },
                }
            } else {
                return {
                    name: 'valuation.request',
                    query: {
                        ...this.$route.query,
                        valuation_type: this.curUser.submitableValuations[0],
                    },
                    params: { action: 'start' },
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.app_card {
    background: white;
    border-radius: 5px;
    border: 1px solid;
    overflow: hidden;
    transition: all 0.2s ease-out;
    border-color: #d6e2e9;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0);
    height: 100%;

    &:hover {
        cursor: pointer;
        border-color: var(--primary);
        box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    }

    &__text {
        padding: 24px;

        title {
            color: #001837;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            margin-top: 8px;
            color: #798da6;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__pict {
        background-color: #f2f4f9;
    }
}
</style>
