import { i18n } from '@/i18n'
import store from '@/store'
import utils from '@/shared/plugins/utils'
const t = i18n.global.t

// const config = store.getters.getConfig

const getColumns = ({ curUser, isUserRequests }) =>
    [
        {
            name: 'deployment',
            label: t('monolith.shared.customer'),
            width: '30',
            visible: store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length > 0,
            searchable: true,
            align: 'left',
        },
        {
            name: 'customer_ref',
            // label: t('monolith.shared.customer_ref'),
            label: 'Customer ref',
            width: '40',
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'valuation_request_ref',
            visible: !store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length > 0,
            label: t('monolith.shared.request_ref'),
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'valuation_type',
            visible: curUser.accessibleModules.length > 1,
            label: t('monolith.shared.type'),
            align: 'center',
        },
        {
            name: 'building_type',
            label: t('features.f_building_type'),
            align: 'center',
            format: (buildingType) => (buildingType ? t(`value.${buildingType}`) : ''),
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'displayAddress',
            label: t('monolith.shared.address'),
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'status',
            label: t('monolith.shared.status_col'),
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'created_at',
            label: t('monolith.shared.created_at'),
            align: 'center',
            format: utils.formatDate,
            searchable: store.getters.getConfig.SHOW_DATE_FILTERS,
        },
        {
            name: 'modified_at',
            label: t('monolith.shared.last_modified'),
            align: 'center',
            format: utils.formatDate,
            searchable: store.getters.getConfig.SHOW_DATE_FILTERS,
        },
        {
            name: 'action',
            label: t('monolith.shared.actions'),
            align: 'center',
            searchable: false,
            sortable: false,
        },
        {
            name: 'owner',
            label: t('monolith.shared.owner'),
            visible: !isUserRequests,
            align: 'center',
            format: (owner) => (owner ? owner.email : ''),
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'last_submission_lang',
            label: t('monolith.global.language'),
            visible: curUser.hasRole(['dispatcher', 'valuer']),
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'valuer',
            label: t('monolith.shared.valuer'),
            visible: curUser.hasRole('dispatcher'),
            align: 'center',
            format: (valuer) => (valuer?.assigned ? valuer.username : 'N/A'),
            // searchable: !isCustomerRefSearch,
        },
    ].map((column) => ({ field: column.name, visible: true, sortable: true, ...column }))

// const getDuplicatesColumns = () => [
//     {
//         field: 'deployment',
//         label: $t('monolith.shared.customer'),
//         width: '30',
//         visible: store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length,
//     },
//     {
//         field: 'customer_ref',
//         label: $t('monolith.shared.customer_ref'),
//         width: '40',
//         sortable: true,
//         align: 'center',
//     },
//     {
//         field: 'valuation_request_ref',
//         label: $t('monolith.shared.request_ref'),
//         visible: !store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length,
//         align: 'center',
//     },
//     {
//         field: 'display_request_ref',
//         label: $t('monolith.shared.request_ref'),
//         visible: store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length,
//         align: 'center',
//     },
//     {
//         field: 'address',
//         label: $t('monolith.shared.address'),
//         align: 'center',
//     },
//     {
//         field: 'reasons',
//         label: $t('dvm.shared.duplicates_modal.reason'),
//         sortable: true,
//     },
//     {
//         field: 'displayStatus',
//         label: $t('monolith.shared.status_col'),
//         align: 'center',
//         sortable: true,
//     },
//     {
//         field: 'created_at',
//         label: $t('monolith.shared.created_at'),
//         align: 'center',
//         sortable: true,
//         format: utils.formatDate,
//     },
//     {
//         field: 'displayOwner',
//         label: $t('monolith.shared.owner'),
//         visible: requestFilter !== 'my_requests',
//         align: 'center',
//         sortable: true,
//     },
//     {
//         field: 'displayValuer',
//         label: $t('monolith.shared.valuer'),
//         visible: hasRole('dispatcher'),
//         align: 'center',
//         sortable: true,
//     },
// ]

export { getColumns }
