import ReButton from '@/components/elements/ReButton.vue'
import ReField from '@/components/elements/ReField.vue'
import ReInput from '@/components/elements/ReInput.vue'
import ReSelect from '@/components/elements/ReSelect.vue'
import ReTooltip from '@/components/elements/ReTooltip.vue'
import ReIcon from '@/components/elements/ReIcon.vue'
import ReTitle from '@/components/elements/ReTitle.vue'
import ReMultiChoice from '@/components/elements/ReMultiChoice.vue'
import ReDateTimePicker from '@/components/elements/ReDateTimePicker.vue'
import RePage from '@/components/elements/RePage.vue'
import ReDialog from '@/components/elements/ReDialog.vue'
import ReBanner from '@/components/elements/ReBanner.vue'
import ReLoading from '@/components/elements/ReLoading.vue'
import ReSvg from '@/components/elements/ReSvg.vue'
import ReSlider from '@/components/elements/ReSlider.vue'
import ReForm from '@/components/elements/ReForm.vue'
import ReStatic from '@/components/elements/ReStatic.vue'

const ReLibrary = {
    install(app) {
        app.component('ReButton', ReButton)
        app.component('ReField', ReField)
        app.component('ReInput', ReInput)
        app.component('ReSelect', ReSelect)
        app.component('ReTooltip', ReTooltip)
        app.component('ReIcon', ReIcon)
        app.component('ReTitle', ReTitle)
        app.component('ReMultiChoice', ReMultiChoice)
        app.component('ReDateTimePicker', ReDateTimePicker)
        app.component('RePage', RePage)
        app.component('ReDialog', ReDialog)
        app.component('ReBanner', ReBanner)
        app.component('ReLoading', ReLoading)
        app.component('ReSvg', ReSvg)
        app.component('ReForm', ReForm)
        app.component('ReSlider', ReSlider)
        app.component('ReStatic', ReStatic)
    },
}

export default ReLibrary
