<template>
    <template
        v-if="
            isValuerOrTypeDispatcher &&
            ['submitted', 'needs-review', 'valued', 'exotic'].includes(props.row.status)
        "
    >
        <a
            v-if="props.row.deployment"
            :href="`//${props.row.deployment}.rock.estate/valuation/${$route.params.lang}/request/extra-info?valuation_request_ref=${props.row.displayRequestRef}`"
            target="_blank"
        >
            {{ props.row.displayRequestRef }}
        </a>
        <router-link
            v-else
            :to="{
                name: 'valuation.request',
                params: { action: 'extra-info' },
                query: { valuation_request_ref: props.row.valuation_request_ref },
            }"
        >
            {{ props.row.displayRequestRef }}
        </router-link>
    </template>
    <span v-else>
        {{ props.row.displayRequestRef }}
    </span>
    <re-button
        v-if="isValuerOrTypeDispatcher && props.row.other_data?.similar_requests?.length"
        size="sm"
        color="warning"
        dense
        class="q-ml-sm"
        @click="emit('show-duplicates', props.row)"
    >
        <re-icon svg-name="duplicates" />
    </re-button>
</template>
<script setup>
import { inject, computed } from 'vue'

// don't automatically insert attrs in root div:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    value: {
        type: String,
        default: '',
    },
    row: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['show-duplicates'])

const curUser = inject('curUser')

const isValuerOrTypeDispatcher = computed(
    () => curUser.hasRole('dispatcher', props.row.valuation_type) || props.row.valuer?.is_self
)
</script>
