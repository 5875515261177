import { computed, ref, unref, reactive, watch } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'
import deepmerge from 'deepmerge'
import _ from 'lodash'
import { useRoute } from 'vue-router'

import useStreeView from './streetView.js'
import wrapRequest from './requestWrapper.js'
import { OFFLINE_MUTATION_PREFIX, cleanQueryKey } from '@/composables/query'
import utils from '@/shared/plugins/utils'
import { queryClient } from './query.js'
import { reactiveAny } from './utils/reactive.js'

export default function useRequest(requestRefInit = null, { startDisabled } = {}) {
    // const route = useRoute()
    // const { hasRole } = useUser()
    // rootGetters['auth/hasRole'] = hasRole

    // TODO: deal with unread_messages
    // TODO: fetch documents on request update?
    // TODO: deal with fetchStreetviewInfo

    // TODO: decide if we want to allow updating requestRef (or force a new composable instance)
    // const requestRef = ref(requestRefInit)

    // const queryClient = useQueryClient()

    // Easier to grab the route directly here:
    const route = useRoute()
    // TODO: use Vuex store to get stored requestRef
    const requestRef = computed(
        () => unref(requestRefInit) || route.query.valuation_request_ref || route.query.ref
    )
    const disable = ref(!!startDisabled)
    const isEnabled = computed(() => !disable.value && !!requestRef.value)
    const queryKeyBase = ['valuation', 'request', requestRef]
    const queryKey = [...queryKeyBase, { details: 'full' }]

    // NOTE: when called with an empty initial requestRef, this will create an inactive invalid query
    // which will fail when invalidating and not filtering for active…
    // TODO: avoid that?
    const query = useQuery({
        queryKey,
        // TODO: check that the wrapper gets run when the cache gets updated directly…
        select: function (data) {
            // TODO: handle error (data is null/undefined?)
            if (data) {
                return wrapRequest(data)
            }
            return data
        },
        placeholderData: null,
        enabled: isEnabled,
    })

    const mutation = useMutation({
        mutationKey: [OFFLINE_MUTATION_PREFIX, ...queryKeyBase],
        // TODO: add this pre-treatment before mutation:
        // delete features.f_building_type
        // const now = new Date()
        // const valuationDate = `${now.getFullYear()}-${now.getMonth() +
        //     1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
        // const base_valuation = {
        //     date: valuationDate,
        //     type: getters.getValuationType.value,
        //     // TODO: add valuer: { data from the valuation request assigned valuer }
        // }
        // const data = {
        //     valuation: { ...getters.getValuation.value, ...base_valuation },
        //     dvm_features: getters.getDVMFeatures,
        //     ovm_features: getters.getOVMFeatures,
        //     features,
        // }

        // NOTE: Most handling is set in the defaultOptions
    })

    // NOTE: we use a separate mutation for status/type/etc updates that should be online only
    // this mutation omits the OFFLINE_MUTATION_PREFIX or OPTIMISTIC_MUTATION_PREFIX
    const onlineMutation = useMutation({
        onMutate: () => {
            // Re-enable the request so that auto-refetch also updates the list
            disable.value = false
        },
    })

    // TODO: move debouncing to query level?
    const DEBOUNCE_TIME = 1000
    const patchData = ref({})
    const debouncedPatch = _.debounce(function (options = {}) {
        mutation.mutate({
            verb: 'PATCH',
            queryKey: queryKeyBase,
            data: patchData.value,
            ...options,
        })
        patchData.value = {}
    }, DEBOUNCE_TIME)

    // Options:
    //      prefix: prepend with a path (eg dvm_features., ovm_features., etc)
    //      extraPatchData: additional data to be patched when setting the value
    //      debounce: use debounced patching (default false)
    //      forceRefetch: force a refetch after patching (default false)
    //      mergeOptions: options passed to deepmerge (allows finer merge strategy)
    //      overwriteKey: key to overwrite rather than merge (useful for arrays)

    const patch = function (data, options = {}) {
        if (!requestRef.value) {
            console.warn('Trying to patch a blank request:', data, options)
            return
        }
        if (options.readOnly) {
            console.warn('Trying to patch a readOnly field', data, options)
            return
        }

        options.mergeOptions = options.mergeOptions || {}

        if (options.combineArrays) {
            // When merging arrays, combine same indexes, rather than append:
            options.mergeOptions.arrayMerge = (target, source, options) => {
                const destination = target.slice()
                source.forEach((item, index) => {
                    if (typeof destination[index] === 'undefined') {
                        destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
                    } else if (options.isMergeableObject(item)) {
                        destination[index] = deepmerge(target[index], item, options)
                    } else if (target.indexOf(item) === -1) {
                        destination.push(item)
                    }
                })
                return destination
            }
        } else {
            // Default to replacing array:
            options.mergeOptions.arrayMerge = (target, source) => source
        }

        if (options.overwriteKey) {
            // when handling 'props.feature': overwrite rather than merge:
            // eg: { foo: [1, 2] } + { foo: [3] } -> { foo: [3] }
            options.mergeOptions.customMerge = (key) => {
                if (key == options.overwriteKey) return (_, b) => b
            }
        }

        if (options.extraPatchData) data = deepmerge(data, options.extraPatchData)

        // if patching an array, the backend will overwrite existing array, so we need
        // to merge the new data with the existing data first:
        if (
            // FIXME: shouldn't look only at options.prefix (but also key's path)
            options.prefix &&
            options.combineArrays &&
            // Do not add to patchData if the key is already set in patchData:
            (!options.debounce || _.get(patchData.value, options.prefix) === undefined)
        ) {
            const oldData = query.data.value
            if (oldData) {
                // Extract array containing only the data to be patched:
                const old = _.get(oldData, options.prefix)
                if (old) {
                    data = deepmerge(_.set({}, options.prefix, old), data, options.mergeOptions)
                }
            } else {
                console.warn('Patching an array without existing data. This might lead to losing data.')
            }
        }

        if (options.debounce) {
            patchData.value = deepmerge(patchData.value, data, options.mergeOptions)
            if (options.reactive ?? options.debounce) {
                // Optimistically apply the change (before the debounce):
                queryClient.setQueryData(cleanQueryKey(queryKey), (old) => {
                    return deepmerge(old, data, options.mergeOptions)
                })
            }
            debouncedPatch(options)
        } else {
            return mutation.mutateAsync({
                verb: 'PATCH',
                queryKey: queryKeyBase,
                data,
                ...options,
            })
        }
    }

    const putUpdate = function (key, data) {
        return onlineMutation.mutateAsync({
            verb: 'PUT',
            queryKey: [...queryKeyBase, key, data],
            refreshQueryKey: queryKeyBase,
            forceRefetch: true,
        })
    }

    const updateBorrower = (data) => putUpdate('borrower', data)
    const updateValuer = (data) => putUpdate('valuer', data)
    const updateOwner = () => putUpdate('owner', {})
    const updateType = (reqType, data) => putUpdate('type', { type: reqType, ...data })
    const updateStatus = (action, data) => putUpdate('status', { action, ...data })

    // Internal function used to set a value in the reactive object
    function _setVal(key, value, options) {
        if (options.precision) value = Math.round(value * 10 ** options.precision) / 10 ** options.precision

        if (value === undefined) {
            console.warn('Trying to patch with undefined value. Casting to null.', key, value)
            value = null
        }

        // Don't trigger a patch if value unchanged:
        if (_.get(query.data.value, key) === value) return

        patch(_.set({}, key, value), {
            debounce: true,
            ...options,
        })
    }

    const optionDoc = {
        prefix: 'prepend with a path (eg dvm_features, ovm_features, etc) (do not include final `.`)',
        initOnly: 'send back copy of the value once loaded (useful for initial values)',
        precision: 'round to a given precision',
        readOnly: 'prevent setting the value',
        placeholder: 'if value is undefined, return this value (does not update backend)',
        defaultValue: 'if value is undefined, will update backend and return defaultValue',
        mustExist: 'will throw an error if set to true and trying to reach an inexistant key (default false)',
        // Patch options:
        extraPatchData: 'additional data to be patched when setting the value',
        debounce: 'use debounced patching (default true)',
        mergeOptions: 'options passed to deepmerge (allows finer merge strategy)',
        reactive: 'when debouncing: apply change optimistically (default to value of debounce)',
        forceRefetch: 'force a refetch after patching (default false)',
        combineArrays: 'when merging arrays, combine same indexes, rather than append',
        overwriteKey: 'key to overwrite rather than merge (useful for arrays)',
        reactiveObject: 'return an object containing reactive values',
    }

    const getReactive = function (field, options = {}) {
        Object.keys(options).forEach((key) => {
            if (!optionDoc[key]) {
                console.warn(`Unknown option for ${field}: ${key}`)
            }
        })

        if (options.prefix) {
            field = `${options.prefix}.${field}`
        }

        if (options.initOnly) {
            const val = query.data.value
                ? _.get(query.data.value, field, options.defaultValue ?? options.placeholder)
                : options.placeholder
            const valRef = ref(_.cloneDeep(val))

            // NOTE: only watch if the query has not already been set:
            if (!query.data.value) {
                const unwatch = watch(query.data, function (newVal) {
                    valRef.value = _.cloneDeep(newVal)
                    if (options.initOnly && query.data?.value) {
                        unwatch()
                        return
                    }
                })
            }

            return valRef
        }

        return computed({
            get() {
                if (!query.data || !query.data.value) return undefined

                if (options.mustExist === true && !_.has(query.data.value, field))
                    throw new Error(`Error getting reactive value: ${field}`)

                let val = _.get(query.data.value, field)

                if (val && options.precision)
                    val = Math.round(val * 10 ** options.precision) / 10 ** options.precision

                // If value is undefined, return placeholder or defaultValue:
                if (val === undefined) {
                    val = options.defaultValue ?? options.placeholder
                    // When defaultValue is set, make sure to trigger a backend patch
                    if (options.defaultValue !== undefined) _setVal(field, val, options)
                }

                // Wrap object in reactive proxy to allow setting nested values:
                if (typeof val === 'object' && val !== null) {
                    val = new Proxy(val, {
                        set: function (_target, key, value) {
                            _setVal(`${field}.${key}`, value, options)
                            return true
                        },
                    })
                }

                return val
            },
            set(val) {
                _setVal(field, val, options)
            },
            // async set(val) {
            //     if (options.precision)
            //         val = Math.round(val * 10 ** options.precision) / 10 ** options.precision

            //     if (val === undefined) {
            //         console.warn('Trying to patch with undefined value. Casting to null.')
            //         val = null
            //     }

            //     // Don't trigger a patch if value unchanged:
            //     if (_.get(query.data.value, field) === val) return

            //     await patch(_.set({}, field, val), {
            //         debounce: true,
            //         ...options,
            //     })
            // },
        })
    }

    // Check options doc on getReactive
    const mapReactives = (fields, options = {}) => {
        return utils.arrayObjectMap(fields, (f) => [
            f.split('.').at(-1).replace('[0]', ''),
            getReactive(f, {
                // If prefixed key contains [0], automatically combine arrays:
                // TODO: only combine key, not all arrays
                combineArrays: f.includes('[0]') || options.prefix?.includes('[0]'),
                ...options,
            }),
        ])
    }
    const mapReadOnly = (fields, options = {}) => {
        return mapReactives(fields, { ...options, readOnly: true })
    }
    const mapReactiveNumbers = (fields, options = {}) => {
        return mapReactives(fields, { ...options, precision: 2 })
    }

    const initValues = (fields, options = {}) => mapReactives(fields, { initOnly: true, ...options })

    // const loadReactives = (fields) => {}

    // TODO: check if we need both this reactive and top-level?
    const is = reactive({
        // Building type:
        // TODO: do these utils (is_house etc) really need to be defined outside of this composable?
        house: computed(() => utils.is_house(query.data?.value?.features?.f_building_type)),
        apartment: computed(() => utils.is_apartment(query.data?.value?.features?.f_building_type)),
        plot: computed(() => utils.is_plot(query.data?.value?.features?.f_building_type)),
        new: computed(() => utils.is_new(query.data?.value?.features?.f_building_type)),
        existing: computed(() =>
            ['house', 'apartment'].includes(query.data?.value?.features?.f_building_type)
        ),
        building: computed(() => utils.is_building(query.data?.value?.features?.f_building_type)),
        // Status:
        draft: computed(() => query.data?.value?.status == 'draft'),
        // Type:
        ovm: computed(() => query.data?.value?.valuation_type === 'ovm'),
        avm: computed(() => query.data?.value?.valuation_type === 'avm'),
        dvm: computed(() => query.data?.value?.valuation_type === 'dvm'),
        valued: computed(() => query.data?.value?.valuation_type === 'valued'),
        submitted: computed(() => query.data?.value?.status === 'submitted'),
    })

    const onLoad = function (callback) {
        if (query.isFetched.value) {
            callback(query.data.value)
        } else {
            const unwatch = watch(query.isFetched, function () {
                if (query.isFetched.value) {
                    callback(query.data.value)
                    unwatch()
                }
            })
        }
    }

    // TODO: move/merge with query cache update
    watch(query.data, function (newVal) {
        if (!newVal) return
        queryClient.setQueriesData({ queryKey: ['valuation', 'requests'] }, (requestData) => {
            // FIXME: sometimes this seems to be getting cache from the wrong query:
            // console.debug('Updating request in cache', requestData)
            return requestData.map((req) => {
                if (req.valuation_request_ref === newVal.valuation_request_ref) {
                    return newVal
                }
                return req
            })
        })
    })

    // NOTE: Need to create these here bc we neeed setup context for the composable
    // (nil coords will cause the streetview query to be inactive)
    var coords = ref({})
    const streetViewQuery = useStreeView(coords)

    // TODO: move streetview to its own composable:
    const getStreetView = function () {
        coords.value = computed(() => {
            return {
                lat: query.data.value?.features?.f_lat,
                lng: query.data.value?.features?.f_lng,
            }
        })
        return streetViewQuery
    }

    return reactive({
        requestRef,
        queryKeyBase,
        getRequestRef: reactive(computed(() => requestRef)),
        mutation,
        onlineMutation,
        isBusy: reactiveAny(
            query.isFetching,
            query.isLoading,
            mutation.isPending,
            onlineMutation.isPending,
            streetViewQuery.isFetching
        ),
        ...query,

        isLoaded: computed(() => query.data.value !== null),
        onLoad,
        updateStatus,
        updateType,
        updateBorrower,
        updateValuer,
        updateOwner,
        patch,

        getStreetView,

        // TODO: do we need to expose these?
        // getFeatures: computed(() => query.data?.value?.features),
        getLevel: computed(() => query.data?.value?.features?.level),

        getReactive,
        mapReactives,
        // TODO: enable when we switch to Vue3 and can get reactivity working
        // $r: reactives,
        mapReadOnly,
        mapReactiveNumbers,
        initValues,
        is,
    })
}
